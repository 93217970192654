import { Card, Col, Form, Modal, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import isEqual from "lodash/isEqual";
import { FieldData } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import t from "../../../../../../app/i18n";
import { SecondaryButton } from "../../../../../../common/components/buttons/SecondaryButton";
import AntIcon, { type AntIconType } from "../../../../../../common/components/icons/AntIcon";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import { rowGutter } from "../../../../../../common/constants";
import { Permission } from "../../../../../../common/security/authorization/enums";
import type { FieldConstraintViolation, RootState } from "../../../../../../common/types";
import {
  licensePlateNormalizeFunction,
  resolveFormValidationError,
  setErrorsToForm
} from "../../../../../../common/utils/formUtils";
import { useScrollToTopOnLoad } from "../../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { removeStringWhiteSpaces } from "../../../../../../common/utils/utils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { selectContainedPermissions } from "../../../../../auth/ducks";
import { CalcValidationGroup, ClientFormType, ClientType } from "../../../../../client/enums";
import { Client, CreateUpdateContractClient } from "../../../../../client/types";
import {
  clientToCreateUpdateContractClient,
  createUpdateContractClientToClient,
  processClientsDataViolations,
  resolveClientIdentifier,
  useClientValidation
} from "../../../../../client/utils";
import { DashboardNoticePosition } from "../../../../../dashboard/enums";
import { CalcType } from "../../../../enums";
import { CALC_ROUTE_PATHS } from "../../../../paths";
import CalcNavigation from "../../../components/CalcNavigation";
import CalcNoticesIconView from "../../../components/CalcNoticesIconView";
import { downloadCardReaderActions } from "../../../ducks";
import { CalcDataSource, CalcResult } from "../../../types";
import { deleteStateVehicleCalcResultsAction } from "../../ducks";
import { VehicleOwnerRelation, VehiclePolicyHolderRelation } from "../../enums";
import type {
  VehicleCalc,
  VehicleCalcClientsData,
  VehicleCalcForm,
  VehicleCalcFormClientsData,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleCalcType,
  VehicleCalcVehicleData,
  VehicleFormClients,
  VehicleOfferType
} from "../../types";
import { hasAnyVehicleResult, M1_N1 } from "../../utils";
import VehicleCalcResultsView from "../result/VehicleCalcResults";
import VehicleCalcClientsDataSection from "./sections/VehicleCalcClientsDataSection";
import VehicleCalcGeneralAndReinsurancesDataSection from "./sections/VehicleCalcGeneralAndReinsurancesDataSection";
import VehicleCalcVehicleDataSection from "./sections/VehicleCalcVehicleDataSection";

interface Props {
  calcData?: VehicleCalc;
  calcDataSource: CalcDataSource;
  calcResults: VehicleCalcResults;
  holder?: Client;
  onCalcResultsDelete: typeof deleteStateVehicleCalcResultsAction;
  onDownloadCardReader: typeof downloadCardReaderActions.request;
  onCalculationFormSubmit: (calcData: VehicleCalc) => void;
  onGenerateContractClick: (calcData: VehicleCalc, selectedResult: CalcResult<VehicleCalcResultData>) => void;
  onGenerateOfferClick: (type: VehicleOfferType, calcData: VehicleCalc) => void;
  onSaveDraftClick: (calcData: VehicleCalc, overwriteExisting: boolean) => void;
  onClientChange: (type: ClientFormType, client?: Client) => VehicleFormClients;
  onResetCalculatorClick: () => void;
  onCalcDataSourceReset: () => void;
}

const HOLDER_INDEX_MAP = new Map<ClientFormType, number>([[ClientFormType.HOLDER, 0]]);

const VehicleCalcWrapper = (props: Props) => {
  useScrollToTopOnLoad();

  const [form] = Form.useForm<VehicleCalcForm>();
  const clientValidation = useClientValidation();

  const vehicleCalcPermissions = useSelector<RootState, Permission[]>(state =>
    selectContainedPermissions(Permission.MTPL_CALCULATE, Permission.CRASH_CALCULATE)(state)
  );

  const [resultsOpen, setResultsOpen] = useState<boolean>(false);
  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<ClientFormType, FieldConstraintViolation[]>>(
    new Map()
  );

  useEffect(() => {
    if (props.calcData) {
      const licensePlate = props.calcData.vehicleData.licensePlate;

      const holder = createUpdateContractClientToClient(
        props.calcData.clientsData.clients[props.calcData.clientsData.holderIndex] as CreateUpdateContractClient
      );
      form.setFieldsValue({
        ...props.calcData,
        vehicleData: {
          ...props.calcData.vehicleData,
          licensePlate: licensePlate ? licensePlateNormalizeFunction(licensePlate) : undefined
        },
        clientsData: {
          ...props.calcData.clientsData,
          clients: undefined,
          holderIndex: undefined,
          holderIdentifier: holder ? resolveClientIdentifier(holder) : undefined
        }
      } as any);
      props.onCalcDataSourceReset();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientValidation.errorResponse?.violations?.length) {
      setClientsViolationErrors(
        processClientsDataViolations(HOLDER_INDEX_MAP, "clientsData.clients", clientValidation.errorResponse.violations)
      );
    }
  }, [clientValidation.errorResponse]);

  useEffect(() => {
    return () => {
      clientValidation.onErrorResponseDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.calcDataSource === "init" && hasAnyVehicleResult(props.calcResults)) {
      setResultsOpen(true);
    }
  }, [props.calcResults]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInsuranceTypeChange = (e: RadioChangeEvent): void => {
    const calcType = e.target.value as VehicleCalcType;
    const vehicleData = form.getFieldValue(["vehicleData"]) as VehicleCalcVehicleData;
    switch (calcType) {
      case CalcType.MTPL:
        form.setFieldsValue({
          vehicleData: {
            odometer: undefined,
            price: vehicleData.newVehicle && M1_N1.includes(vehicleData.category) ? vehicleData.price : undefined,
            generalPrice: undefined,
            buyingPrice: undefined
          },
          clientsData: {
            holderHasNeverBeenCrashInsured: false
          },
          generalData: {
            crossSelling: {
              kooperativaContract: false
            },
            gapDuration: undefined,
            gapCoverageLimit: undefined
          },
          reinsurancesData: {
            gap: false,
            replacementVehicle: false,
            generaliAbroadVehicleRepair: false,
            koopExtendedWarranty: false
          }
        });
        break;
      case CalcType.CRASH:
        form.setFieldsValue({
          vehicleData: {
            steeringWheelOnTheRight: false,
            emergencyBrakingSystem: false,
            parkingAssistant: false
          },
          reinsurancesData: {
            animal: false,
            element: false,
            theftAndVandalism: false,
            injury: false,
            koopExtendedWarranty: false,
            uniqaSmallDamage: false,
            uniqaTotalDamage: false,
            uniqaLawyerAssistance: false,
            uniqaSidecarMtpl: false,
            uniqaChildrenInjury: false,
            uniqaUnlimitedTow: false
          }
        });
        break;
      case CalcType.MTPL_CRASH:
        form.setFieldsValue({
          vehicleData: {
            steeringWheelOnTheRight: false,
            emergencyBrakingSystem: false,
            parkingAssistant: false
          },
          generalData: {
            crossSelling: {
              csobContract: false,
              kooperativaContract: false,
              unionHealthContract: false
            }
          },
          reinsurancesData: {
            animal: false,
            element: false,
            theftAndVandalism: false,
            generaliAbroadVehicleRepair: false,
            uniqaSmallDamage: false,
            uniqaTotalDamage: false
          }
        });
        break;
    }
  };

  const handleFormFieldsChange = (changedFields: FieldData[]): void => {
    if (hasAnyVehicleResult(props.calcResults) && changedFields?.length > 0) {
      props.onCalcResultsDelete();
    }
  };

  const handleCalculationFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (!props.holder || clientsViolationErrors.has(ClientFormType.HOLDER)) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        } else {
          if (props.calcDataSource !== "init") {
            props.onCalcDataSourceReset();
          }
          props.onCalculationFormSubmit(processAndGetCalcFormData(values));
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const handleHolderChange = (client?: Client): void => {
    if (hasAnyVehicleResult(props.calcResults) && !isEqual(props.holder, client)) {
      props.onCalcResultsDelete();
    }

    props.onClientChange(ClientFormType.HOLDER, client);

    if (client) {
      clientValidation.onValidate({
        prefix: `clientsData.clients[0]`,
        client: clientToCreateUpdateContractClient(client),
        validationGroup: CalcValidationGroup.CALCULATE
      });

      if (client.type !== ClientType.NATURAL) {
        form.setFieldsValue({
          clientsData: {
            holderDrivingLicenseYear: undefined,
            holderIsDisabledPerson: false,
            disabledPersonIdCardNumber: undefined
          },
          generalData: {
            crossSelling: {
              unionHealthContract: false
            }
          }
        });

        if (!form.getFieldValue(["clientsData", "leasing"])) {
          form.setFieldsValue({
            clientsData: {
              policyHolderRelation: VehiclePolicyHolderRelation.SAME_AS_VEHICLE_HOLDER,
              ownerRelation: VehicleOwnerRelation.SAME_AS_VEHICLE_HOLDER
            }
          });
        }
      }
    } else {
      form.setFieldsValue({
        generalData: {
          crossSelling: {
            unionHealthContract: false
          }
        }
      });
    }
  };

  const handleHolderViolationErrorsDelete = (): void => {
    setClientsViolationErrors(new Map<ClientFormType, FieldConstraintViolation[]>());
  };

  const handleResultsSaveDraftClick = (overwriteExisting: boolean): void => {
    setResultsOpen(false);
    if (checkAreCalcFormDataUnchanged()) {
      props.onSaveDraftClick(processAndGetCalcFormData(), overwriteExisting);
    }
  };

  const handleResultsGenerateOfferClick = (type: VehicleOfferType): void => {
    setResultsOpen(false);
    if (checkAreCalcFormDataUnchanged()) {
      props.onGenerateOfferClick(type, processAndGetCalcFormData());
    }
  };

  const handleResultsGenerateContractClick = (result: CalcResult<VehicleCalcResultData>): void => {
    setResultsOpen(false);
    if (checkAreCalcFormDataUnchanged()) {
      const calcFormData = processAndGetCalcFormData();
      if (!calcFormData.vehicleData.vin) {
        messageUtils.errorNotification({
          message: t("error.api.status_422"),
          description: (
            <FieldViolationsView
              violations={[
                {
                  fieldPath: "vehicleData.vin",
                  fieldValue: calcFormData.vehicleData.vin,
                  errors: [t("validation.notNull")]
                }
              ]}
              rootPath="calc.vehicle.attrs"
            />
          ),
          duration: 10
        });
      } else {
        props.onGenerateContractClick(calcFormData, result);
      }
    }
  };

  const handleResultsShowErrorsClick = (result: CalcResult<VehicleCalcResultData>): void => {
    if (result.error) {
      setErrorsToForm(form, "calc.vehicle.attrs", result.error.violations);
      setResultsOpen(false);
      setClientsViolationErrors(
        processClientsDataViolations(HOLDER_INDEX_MAP, "clientsData.clients", result.error.violations)
      );
    }
  };

  const processAndGetCalcFormData = (calcData?: VehicleCalcForm): VehicleCalc => {
    const formValues = calcData ? { ...calcData } : form.getFieldsValue();

    const clientsData: VehicleCalcClientsData & VehicleCalcFormClientsData = {
      ...formValues.clientsData,
      holderIndex: 0,
      clients: props.holder ? [clientToCreateUpdateContractClient(props.holder)] : []
    };
    delete clientsData.holderIdentifier;

    return {
      ...formValues,
      vehicleData: {
        ...formValues.vehicleData,
        licensePlate: removeStringWhiteSpaces(formValues.vehicleData.licensePlate)
      },
      clientsData: clientsData as VehicleCalcClientsData
    };
  };

  const checkAreCalcFormDataUnchanged = (): boolean => {
    const calcDataUnchanged = isEqual(props.calcData, processAndGetCalcFormData());

    if (!calcDataUnchanged) {
      Modal.warning({
        title: t("common.warning"),
        content: t("calc.validations.calcDataChanged"),
        okText: t("calc.actions.recalculationSubmit"),
        maskClosable: true,
        onOk: handleCalculationFormSubmit,
        onCancel: handleCalculationFormSubmit
      });
    }

    if (props.calcDataSource !== "init") {
      props.onCalcDataSourceReset();
    }

    return calcDataUnchanged;
  };

  const resolvePageTitle = (type: CalcType): string => {
    return type ? t("calc.vehicle.titles.calculation." + type) : t("calc.vehicle.titles.noSelectedCalcType");
  };

  const resolveCalcTypeIcon = (type: CalcType): AntIconType => {
    switch (type) {
      case CalcType.MTPL:
        return "dashboard";
      case CalcType.CRASH:
        return "car";
      case CalcType.MTPL_CRASH:
        return "safety";
      default:
        return "dashboard";
    }
  };

  const allowedCalcTypes =
    vehicleCalcPermissions.length === 2
      ? [CalcType.MTPL, CalcType.CRASH, CalcType.MTPL_CRASH]
      : [vehicleCalcPermissions.includes(Permission.MTPL_CALCULATE) ? CalcType.MTPL : CalcType.CRASH];

  return (
    <>
      <Form form={form} layout="vertical" name="vehicleCalcForm" onFieldsChange={handleFormFieldsChange}>
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
          {({ getFieldValue }) => {
            const calcType = getFieldValue("type");
            return (
              <>
                <Card
                  title={<h2>{resolvePageTitle(calcType)}</h2>}
                  className="card-box card-box--inner-extra"
                  extra={
                    <>
                      <CalcNoticesIconView position={DashboardNoticePosition.VEHICLE_CALC} />
                      <Link to={CALC_ROUTE_PATHS.vehicleDraft.to}>
                        <SecondaryButton icon={<AntIcon type="form" />}>{t("calc.actions.showDrafts")}</SecondaryButton>
                      </Link>
                    </>
                  }
                >
                  <Row gutter={rowGutter} justify="center">
                    <Col span={24} className="center-align">
                      <Form.Item
                        name="type"
                        rules={[validations.notNull]}
                        className="no-bottom-space"
                        initialValue={allowedCalcTypes[0]}
                      >
                        <Radio.Group
                          size="large"
                          buttonStyle="solid"
                          className="radio-group-icon"
                          onChange={handleInsuranceTypeChange}
                        >
                          {allowedCalcTypes.map(type => (
                            <Radio.Button value={type} key={type} style={{ width: 150 }}>
                              <AntIcon type={resolveCalcTypeIcon(type)} />
                              <span>{t("calc.enums.calcType." + type)}</span>
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <VehicleCalcResultsView
                  open={resultsOpen}
                  calcType={calcType}
                  calcResults={props.calcResults}
                  onClose={() => setResultsOpen(false)}
                  onSaveDraftClick={handleResultsSaveDraftClick}
                  onGenerateOfferClick={handleResultsGenerateOfferClick}
                  onGenerateContractClick={handleResultsGenerateContractClick}
                  onShowErrorsClick={handleResultsShowErrorsClick}
                />
              </>
            );
          }}
        </Form.Item>

        <VehicleCalcVehicleDataSection form={form} onDownloadCardReader={props.onDownloadCardReader} />

        <VehicleCalcClientsDataSection
          form={form}
          holder={props.holder}
          clientsViolationErrors={clientsViolationErrors}
          onHolderChange={handleHolderChange}
          onHolderViolationErrorsDelete={handleHolderViolationErrorsDelete}
        />

        <VehicleCalcGeneralAndReinsurancesDataSection holder={props.holder} />
      </Form>

      <CalcNavigation
        hasResults={hasAnyVehicleResult(props.calcResults)}
        onFormSubmit={handleCalculationFormSubmit}
        onShowResultsClick={() => setResultsOpen(true)}
        onResetCalculatorClick={props.onResetCalculatorClick}
      />
    </>
  );
};

export default VehicleCalcWrapper;
